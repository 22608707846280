.news-target-form {
  padding: calc(var(--grid-gap) / 2) 0;
  border-bottom: 1px solid var(--border-color);


  &__content {
    display: flex;
    width: 100%;
  }

  &__top {
    display: flex;
  }

  &__bottom {
    display: flex;
    margin-top: calc(var(--grid-gap) / 2);
  }

  &__fields {
    flex: 1 1 auto;
  }

  &__field {
    &:nth-child(n + 2) {
      margin-left: calc(var(--grid-gap) / 2);
    }

    &_group {
      flex: 1 1 auto;
    }
  }

  &__actions {
    margin-left: calc(var(--grid-gap) / 2);
    padding-top: 40px;
  }
}
