.color-select {
  &__wrap {

  }

  &__list {
    display: flex;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  &__input {
    &:checked + label {
      &._color_10 {
        box-shadow: 0 0 7px 0 var(--color-light-gray);
        border: 2px dashed var(--t);
      }

      @for $i from 2 through 8 {
        &._color_#{$i}0 {
          background-color: var(--color-#{$i}0);
          box-shadow: 0 0 7px 0 var(--color-#{$i}0);
        }
      }
    }
  }

  &__label {
    padding: 0 !important;
    margin: 0 !important;
    &::before, &::after {
      display: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 45px;
    height: 45px;
    width: 45px;
    border-radius: 100px;

    transition: {
      property: background-color, box-shadow, border-color;
      duration: .3s;
    };

    &._color_10 {
      border: 2px dashed var(--color-light-gray);
      box-shadow: 0 0 7px 0 var(--t);

      &:hover {
        border: 2px dashed var(--color-dark-gray);
      }
    }

    @for $i from 2 through 8 {
      &._color_#{$i}0 {
        background-color: var(--color-#{$i}0-t);
        border: inset 2px solid var(--color-#{$i}0);

        &:hover {
          box-shadow: 0 0 7px 0 var(--color-#{$i}0);
        }
      }
    }
  }
}
