.slot-time-list {


  .custom-select {
    &__item {
      padding: 1px 0;
    }

    &__choice-name {
      padding: 0 10px;
      font-size: 13px;
    }

    &__content-wrap {
      box-shadow: 0 0 14px 9px #556ba724;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        right: calc(100% - 1px);
        top: 10px;
        border: 10px solid var(--t);
        border-right-color: white;

      }
    }
  }
}
