.temp-class {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
}

.custom-select {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;

  &_type {
    &_multi {
      .custom-select__label {
        //padding: 7px 12px;
      }
    }
  }

  &._open {
    z-index: 500;

    .custom-select {
      &__arrow-icon {
        transform: rotate(-180deg);
      }

      &__content-wrap_openable, &__list {
        max-height: 300px;
      }
    }

    &.custom-select_type_filterable {
      .custom-select {
        &__list {
          max-height: 300px - 54px;
        }
      }
    }
  }


  &__list {
    padding: 0 8px;
  }

  &._disabled {
    pointer-events: none;

    .custom-select__field {
      background-color: var(--color-light-gray);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    flex: 0 0 100%;
  }

  &__field-wrapper {
    width: 100%;
    position: relative;
    z-index: 35;
  }

  &__arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    pointer-events: none;
    transition: {
      property: transform;
      duration: .2s;
    };

    &_absolute {
      position: absolute;
      top: calc(50% - 12px);
      right: 15px;
    }

    &_relative {
      margin-left: 6px;
    }

    svg path {
      fill: var(--color-dark-gray);
    }
  }

  &__field {
    background-color: white;
    height: var(--default-input-height);

    &:read-only {
      cursor: pointer;
      caret-color: var(--t);
      padding-right: 30px;
    }
  }

  &__content-wrap {
    display: block;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 14px;

    &_openable {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      left: 0;
      min-width: 100%;
      top: 100%;
      z-index: 30;
    }
  }

  &__list {
    overflow: auto;
  }

  &__item {
    width: 100%;
    display: block;
    padding: 3px 0;

    .custom-select {
      &__label {
        border-radius: 14px;
        padding: 0 8px;

        &_data {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &._hidden {
      display: none;
    }

    &:hover {
      .custom-select {
        &__label {

        }

        &__choice-name {
          color: var(--color-black);
        }
      }
      //.custom-select__label {
      //  background-color: var(--primary-color-10);
      //}

      //&:hover {
      //  background-color: var(--primary-color-10);
        //background-color: var(--color-background);
      //}
    }
  }

  &__input {
    display: none;

    //&:disabled, &._disabled {
    //  + .custom-select__label > span.custom-select__choice-name {
    //    color: var(--color-dark-gray);
    //  }
    //
    //  &:checked {
    //    + .custom-select__label > span.custom-select__choice-name {
    //      background-color: var(--color-light-gray);
    //      color: var(--color-dark-gray);
    //    }
    //  }
    //}

    &:checked + .custom-select__label {
      background-color: var(--primary-color-10);

      .custom-select__choice-name {
        font-weight: 600;
        color: var(--color-black);
      }
    }

    + .custom-select__label {
      cursor: pointer;
      display: flex;
      //align-items: center;
      //padding: 5px 12px;
      margin: 0;
      transition: {
        property: background-color;
        duration: .3s;
      };

      &_type-url{
        padding: 0;
      }

      &::after, &::before {
        display: none;
      }
    }
  }

  &__choice-name {
    color: var(--color-dark-gray);
    font-size: 14px;
    line-height: 135%;
    //margin-left: 6px;
    padding: 8px;
  }

  &__filter {
    position: relative;
    padding: 0 12px;
    margin-top: 10px;
  }

  &__filter-label {
    height: 34px;
    width: 100%;
    border: 1px solid var(--border-color);
    padding: 7px 32px 7px 8px;
  }

  &__filter-icon {
    display: flex;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 20px;
    transform: translate3d(0, -50%, 0);
    width: 24px;
    height: 24px;
    svg path {
      fill: var(--color-dark-gray);
    }
  }

  &__data-list {
    display: flex;
    flex-direction: column;
    margin-top: -3px;
  }

  &__data-item {
    display: none;

    &._show {
      display: flex;
    }
  }

  &__data-key, &__data-name {
    font-size: 12px;
  }

  &__data-key {
    margin-right: 4px;
  }
}