.client-page {
  padding-right: var(--grid-spacer);
  align-items: start;
  padding-bottom: 60px;


  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--grid-gap);
    align-items: start;
    //grid-template-rows: 500px auto;
  }

  &__balance {
    grid-column: 1/6;
    grid-row: 1/2;
    background-color: white;
  }

  &__info {
    grid-column: 1/6;
    grid-row: 2/4;
  }

  &__invoices {
    grid-column: 6/13;
    grid-row: 1/3;

    .any-filterable__content {
      height: 400px;
    }
  }

  &__events {
    grid-column: 6/13;
    grid-row: 3/4;

    .any-filterable__content {
      height: 400px;
    }
  }
}
