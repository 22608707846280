.pub-item-info {
  &__list {

  }

  &__item {
    display: flex;
    padding: 7px 0;

    &:nth-child(n + 2) {
      border-top: 1px dashed var(--border-color);
    }
  }

  &__name {
    flex: 0 0 150px;
    width: 100px;
    color: var(--color-dark-gray);
    font-size: 13px;
  }

  &__value-wrap {
    flex: 1 1 auto;
    font-size: 13px;
    color: var(--color-black);
  }

  &__link {
    display: flex;
    color: var(--primary-color);
  }

  &__value {

  }
}
