.publication-filter-form {
  &__form {
    background-color: white;
    border-radius: 16px;
    padding: var(--grid-gap);
  }

  &__fields {
    //display: grid;
    //grid-template-columns: repeat(6, 1fr);
    //grid-gap: var(--grid-gap);
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
  }

  &__field {
    flex: 1 0 240px;
  }
}