.progress-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--grid-gap);
  align-items: flex-start;

  &__list {
    grid-column: 1/8;

  }

  &__item {
    background-color: white;
    padding: var(--grid-gap);
    border-radius: 6px;
    box-shadow: 0 6px 58px rgba(196, 203, 214, .104);

    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
    }
  }

  &__progress-wrap {
    padding: 55px var(--grid-gap) 50px 0;
  }

  &__name-wrap {
    display: flex;
  }

  &__progress-bar {

  }

  &__category {
    font-size: 14px;
    color: var(--color-dark-gray);
    margin-right: 5px;
  }

  &__group {
    font-size: 14px;
    color: var(--color-black);
  }

  &__info-list {
    width: 250px;
  }

  &__info-item {
    display: flex;
    padding: 10px 0;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__info-key {
    font-size: 12px;
    color: var(--color-dark-gray);
    flex: 0 0 150px;
  }

  &__info-value {
    font-size: 12px;
    color: var(--color-black);

    &_bold {
      font-weight: bold;
    }

    &_green {
      color: var(--color-green);
    }
  }

  &__summary-block {
    grid-column: 8/12;
    background-color: white;
    padding: var(--grid-gap);
    border-radius: 6px;
    box-shadow: 0 6px 58px rgba(196, 203, 214, .104);


    .progress-list {
      &__info-list {
        width: 100%;
      }

      &__info-key {
        flex: 0 0 50%;
      }
    }
  }

  &__summary-title {
    margin-bottom: 10px;
  }

  &__total-reward {
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-end;
    padding-top: var(--grid-gap);
    font-weight: bold;
    font-size: 16px;
  }
}
