.date-input {
  input[type='date'], input[type='datetime-local'] {
    position: relative;
    height: var(--default-input-height);
    border: 1px solid var(--border-color);
    border-radius: 14px;
    box-shadow: none;
    display: block;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 400;
    line-height: 171%;
    margin: 0;
    padding: 0 14px;
    transition: border-color var(--default-timing-function) var(--default-transition-time);
    width: 100%;

    color: var(--color-dark-gray);

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      height: 24px;
      width: 24px;
    }

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      right: 18px;
      top: 12px;
      background-image: url('../images/svg/calendar.svg');
      background: {
        repeat: no-repeat;
        size: contain;
        position: center;
      };
      width: 24px;
      height: 24px;
    }
  }
}
