.client-item{
  display: grid;
  grid-template-columns: repeat(6, 1fr) 100px;
  background: #FFFFFF;
  box-shadow: 0 6px 58px 0 #C4CBD61A;
  padding: 20px 30px;
  border-radius: 20px;
  grid-column-gap: 30px;
  align-items: center;

  &__company{
    margin-top: 5px;
    color: var(--color-dark-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
  }

  &__name-wrap, &__field{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }

  &__field{
    &_icon{
      flex-wrap: wrap;
      align-content: flex-end;
    }
  }

  &__field-title{
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    color: var(--color-dark-gray);
  }

  &__field-value{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__more-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 15px;
    background: var(--bg-color);
  }
}