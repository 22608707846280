.slots-table {
  --day-width: 135px;
  --time-width: 87px;
  --cell-width: 150px;
  --cell-height: 28px;

  --day-shift: calc(var(--sidebar-with-spacer) + var(--grid-spacer));
  --time-shift: calc(var(--day-width) + var(--day-width));
  --data-shift: calc(var(--time-shift) + var(--time-width));

  &__table {}

  &__rows {
    position: relative;
  }

  &__heading-row {
    position: sticky;
    top: 13px;
    z-index: 50;
    border-bottom: 1px solid var(--table-dark-color);
  }

  &__heading-cell {
    background-color: var(--bg-color);

    &:not(:last-child) {
      border-right: 1px solid var(--table-dark-color);
    }
    &:last-child {
      .slots-table__heading-text {
        border-radius: 0 24px 0 0;
      }
    }

    &_day {
      z-index: 62;
      .slots-table__heading-text {border-radius: 24px 0 0 0}
    }
    &_time {
      z-index: 60;
    }

    &_group {}

    &._hide {
      display: none;
    }
  }

  &__heading-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-black);
    background-color: var(--color-bg-element);
    height: var(--cell-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__heading-text-elipsed {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px;
  }

  &__day-row {
    position: relative;
    z-index: 40;

    &:nth-child(n + 2) {
      border-bottom: 3px solid var(--table-dark-color);
    }

    &:hover {
      z-index: 41;
    }
  }

  &__day-cell {
    z-index: 42;
  }

  &__rows {}

  &__row {
    position: relative;
    z-index: 19;

    &:hover {
      z-index: 20;

      .slots-table {
        &__time {
          //background-color: var(--color-green-10);
          background-color: var(--primary-color-10);
        }
        &__cell, &__time {
          //box-shadow: 0 1px 1px 0 var(--color-green), 0 -1px 1px 0px var(--color-green);
          box-shadow: 0 1px 1px 0 var(--primary-color), 0 -1px 1px 0px var(--primary-color);
        }
      }
    }
    &:nth-child(n + 2) {
      border-top: 1px solid var(--table-light-color);
    }

    &._hidden {
      display: none;
    }
  }

  &__day-cell, &__heading-cell_day {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: calc(-1 * var(--grid-spacer));
      width: var(--grid-spacer);
      background-color: var(--bg-color);
      top: 0;
      height: calc(100% + 1px);
    }
  }

  &__time-cell {
    z-index: 40;
  }

  &__time {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-dark-gray);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    &._red{
      background: var(--color-30-tt);
    }
  }

  &__cell {
    text-align: center;
    background-color: white;

    &._hide {
      display: none;
    }
  }

  &__day-cell, &__time-cell, &__cell:nth-child(n+2) {
    border-right: 1px solid var(--table-dark-color);
  }

  &__day-cell, &__time-cell {
    background-color: white;
  }

  &__day-cell, &__heading-cell_day {
    position: sticky;
    left: var(--grid-spacer);
  }

  &__time-cell, &__heading-cell_time {
    position: sticky;
    left: calc(var(--grid-spacer) + var(--day-width));
  }

  &__day-cell, &__heading-cell_day, &__time-cell, &__heading-cell_time {
  }

  &__heading-row, &__day-row, &__row {
    display: flex;
  }

  &__day-cell, &__heading-cell_day {
    flex: 0 0 var(--day-width);
    width: var(--day-width);
  }

  &__time-cell, &__heading-cell_time {
    flex: 0 0 var(--time-width);
    width: var(--time-width);
  }

  &__cell, &__heading-cell_group {
    flex: 0 0 var(--cell-width);
    width: var(--cell-width);
    height: 28px;
  }
}
