.vk-page {
  width: 100%;
  &__content {

  }

  &__tokens {
    grid-column: 1/6;
    grid-row: 1/2;
  }

  &__matcher {
    grid-column: 6/13;
    grid-row: 1/3;
  }
}
