.token-list {
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 6px 58px rgba(196, 203, 214, .104);
  padding: 20px var(--grid-spacer) 10px;

  &__heading {
    margin-bottom: var(--grid-gap);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
  }

  &__list {

  }

  &__item {
    display: flex;
    align-items: flex-end;
    padding: 10px 0;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__number {
    flex: 0 0 50px;
    font-weight: bold;
    color: var(--color-light-gray);
    font-size: 14px;
  }

  &__vk-user {
    font-size: 14px;
    color: var(--primary-color);
    flex: 0 0 120px;
  }

  &__date-wrap {
    display: flex;
    flex-direction: column;
    flex: 0 0 150px
  }

  &__date-label {
    font-size: 12px;
    color: var(--color-dark-gray);
    margin-bottom: 5px;
  }

  &__date {
    font-size: 12px;
    color: var(--color-black);

    &._green {
      color: var(--color-green);
    }

    &._red {
      color: var(--color-red);
    }
  }

  &__actions {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  &__action {
    flex: 0 0 44px;
    height: 44px;
    width: 44px;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    border-radius: 14px;
    transition: background-color .4s;

    &:nth-child(n + 2) {
      margin-left: 6px;
    }

    &:hover {
      background-color: var(--primary-color);
      svg path {
        &[stroke] {
          stroke: white;
        }
        &[fill] {
          fill: white;
        }
      }
    }

    svg {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;

      path {
        transition: {
          property: fill, stroke;
          duration: .4s;
        }
      }
    }
  }

  &__empty {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark-gray);
    text-align: center;
    padding: var(--grid-gap);
  }
}
