.client-balance {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 6px 58px rgba(196, 203, 214, .104);
  padding: 20px var(--grid-spacer) 10px;

  &__inner {
    display: flex;
  }

  &__item {
    flex: 0 0 50%;
  }

  &__label {
    color: var(--color-dark-gray);
    font-size: 14px;
  }

  &__value {
    color: var(--color-black);
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px;

    &._red {
      color: var(--color-red);
    }
  }
}
