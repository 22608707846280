.uikit-page {
  &__element-desc {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: var(--primary-color);
    color: white;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}