.news-after-form {
  &__label {
    color: var(--color-dark-gray);
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 171%;
    margin-bottom: 6px;
  }

  &__target-list {

  }

  &__actions {
    margin-top: calc(var(--grid-gap) / 2);
  }

  &__add-link {
    display: flex;
    align-items: center;
  }

  &__add-link-name {
    color: var(--primary-color);
  }

  &__add-link-icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    svg path {
      fill: var(--primary-color);
    }
  }
}