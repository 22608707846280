.invoice-form {
  min-width: 400px;

  &__title {
    padding-bottom: var(--grid-gap);
  }

  &__form {
  }

  &__button-wrap {
    padding-top: var(--grid-gap);
    display: flex;
    justify-content: center;
  }
}