.client-form{
  width: 1000px;
  &__title{
    margin-bottom: 20px;
  }

  form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    button{
      width: fit-content;
      height: fit-content;
      align-self: end;
      justify-self: end;
      grid-column: 2 / 3;
    }
  }
}