.main-menu {
  &__list {
  }

  &__item {
    position: relative;

    &:nth-child(n + 2) {
      margin-top: 10px;
    }

    &._selected {
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 4px;
        background-color: var(--primary-color);
        right: calc(-1 * var(--sidebar-padding));
        border-radius: 2px;
      }

      .main-menu__link {
        background-color: var(--primary-color-10);
      }
    }

    &:hover, &._selected {
      .main-menu {
        &__icon {
          svg path{
            &[fill] {
              fill: var(--primary-color);
            }
            &[stroke] {
              stroke: var(--primary-color);
            }
          }
        }

        &__text {
          color: var(--primary-color);
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 10px 8px;
    border-radius: 10px;
    transition: color .4s;
  }

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-right: 16px;

    svg path {
      &[fill] {
        transition: fill .4s;
      }
      &[stroke] {
        transition: fill .4s;
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-dark-gray);
  }
}
