.back-link {
  white-space: nowrap;
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 40px;

  &__link {
    display: flex;
    align-items: center;

    &:hover {
      .back-link {
        &__name {
          transform: translateX(-4px);
        }
      }
    }
  }

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    svg path {
      fill: var(--primary-color);
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary-color);
    transition: transform .4s;
  }
}
