.publication-list {
  position: relative;

  &__heading {
    display: flex;
    margin-top: 10px;
    border-radius: 3px 3px 0 0;
    //position: sticky;
    top: 80px;
    z-index: 300;
    border: 1px solid var(--border-color);
    width: max-content;
    min-width: 100%;
  }

  &__head {
    padding: 10px 0;
    font-weight: bold;
    background-color: white;
    &:nth-child(n + 2) {
      border-left: 1px solid var(--border-color);
    }

    &_content {
      padding-left: var(--grid-gap);
      flex: 0 0 calc(350px + var(--grid-gap) * 2);
      width: calc(350px + var(--grid-gap) * 2);
    }
    &_info {
      flex: 0 0 calc(350px + var(--grid-gap) * 2);
      width: calc(350px + var(--grid-gap) * 2);
      padding-left: var(--grid-gap);
    }
    &_date {
      flex: 0 0 calc(140px + var(--grid-gap) * 2);
      width: calc(140px + var(--grid-gap) * 2);
      padding-left: var(--grid-gap);
    }
    &_group {
      padding-left: var(--grid-gap);
      flex: 1 0 calc(350px + var(--grid-gap) * 2);
      min-width: calc(350px + var(--grid-gap) * 2);
    }
    &_status {
      flex: 0 0 calc(100px + var(--grid-gap) * 2);
      width: calc(100px + var(--grid-gap) * 2);
      text-align: center;
    }
  }

  &__content {
  }

  &__list {

  }

  &__item {
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 3px solid var(--border-color);
    width: max-content;
    min-width: 100%;
  }

  &__empty {

  }
}