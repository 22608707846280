.notification-list {

  &__item {
    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }

  &__more {
    padding-top: var(--grid-gap);
  }
}