.any-filter {
  padding-bottom: 8px;

  &__form {
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-gap);
  }

  &__field {
  }
}