.invoices-filter {
  padding-bottom: 8px;

  &__form {

  }

  &__fields {
    display: flex;
    align-items: center;
  }

  &__field {
    &:nth-child(n + 2) {
      margin-left: var(--grid-gap);
    }
  }
}