.content-preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  padding-left: var(--sidebar-with-spacer);
  padding-top: var(--top-padding);
  //padding-left: calc(var(--sidebar-with-spacer) + var(--grid-spacer));
  //padding-top: calc(var(--top-padding) + var(--grid-spacer));
  pointer-events: none;
  opacity: 0;
  transition: opacity .4s;

  &._active {
    opacity: 1;
    .content-preloader__content {
      pointer-events: all;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__bg, &__front {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }


  &__bg {
    z-index: 210;
    background-color: rgba(63, 140, 255, 0.03);
    filter: blur(10px);
  }

  &__front {
    z-index: 220;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
