.slot-create-link {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 1px var(--primary-color);

  &:hover {
    opacity: 1;
  }

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    //svg path {
    //  fill: var(--primary-color);
    //}

    background: {
      image: url('../images/svg/plus.svg');
      size: contain;
    };
  }

  &__text {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: var(--primary-color);
  }
}