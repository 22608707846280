.slots-initial {
  width: calc(var(--avail-content-width) - var(--grid-spacer) * 2);
  display: flex;


  &__content {
    flex: 1 1 auto;
    background-color: white;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 6px 58px rgba(196, 203, 214, 0.103611);
    height: calc(100vh - var(--top-padding) - var(--grid-spacer) * 2);
  }

  &__text {
    font-size: 26px;
    color: var(--color-light-gray);
    font-weight: 700;
  }
}
