.error{
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
  }

  &__code{
    color: var(--primary-color);
    font-size: 200px;
    font-weight: bold;
  }

  &__subtitle{
    color: var(--color-black);
    margin-top: 30px;
    font-size: 18px;
    text-align: center;

  }
}