.slot-message {
  padding: 10px 0;

  &__title, &__subtitle, &__date {
    font-size: 16px;
    font-weight: bold;
  }

  &__title, &__date {
    color: var(--color-black);
  }

  &__subtitle {
    color: var(--color-dark-gray);
    margin-top: 5px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
  }

  &__additional {
    font-size: 14px;
    color: var(--color-dark-gray);
  }

  &__date {
    margin-right: -33px;
  }
}

