.salary-page {
  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--grid-gap);
    align-items: start;
  }

  &__progress-list {
    grid-column: 1/13;
  }

  &__managers {
    background-color: white;
    padding: var(--grid-gap);
    grid-column: 1/6;
    border-radius: 10px;
  }

  &__manager {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &__manager-link {
    display: flex;
    padding: 15px 0;
    color: var(--primary-color);
  }
}
