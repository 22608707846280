.any-filterable-list {
  overflow: auto;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  section::-webkit-scrollbar {
    width: 0 !important
  }

  &__list {
  }

  &__item-wrap {
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__item {

  }

  &__empty {

  }
}
