.pub-item-images {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__item {
  }

  &__link {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    overflow: hidden;
  }

  &__image {
    width: 80px;
    height: 80px;
  }

  &__picture {
    display: flex;
  }

  &__info {

  }
}
