.tabs {
  list-style: none;
  display: inline-flex;
  justify-content: flex-start;
  background: var(--color-bg-element);
  border-radius: 24px;
  padding: 4px;

  &_small {
    .tabs {
      &__item {}
      &__link {
        font-size: 14px;
        padding: 6px 11px;
      }
    }
  }

  &__item {
    &._active {
      .tabs__link {
        background-color: var(--primary-color);
        color: white;
        font-weight: 700;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 9px 20px;
    color: var(--color-black);
    font-weight: 400;
    font-size: 16px;
    border-radius: 20px;
    transition: {
      property: color, background-color, font-weight;
      duration: .4s;
    };
  }
}
