.clients{
  position: relative;
  margin-right: 30px;
  &__list{
    display: flex;
    flex-direction: column;
  }

  &__item{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: unset;
    }
  }

  &__new-button{
    z-index: 500;
    position: absolute;
    right: 0;
    top: 6px;
  }
}