.layout-heading {
  padding: var(--grid-spacer);
  padding-top: calc(var(--grid-spacer) * 2 + 40px);
  position: relative;
  z-index: 200;

  &__actions {
    position: fixed;
    right: 0;
    top: 0;
    padding: var(--grid-spacer);
    width: calc(var(--avail-content-width));
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg-color);
  }

  &__back-wrap {

  }

  &__buttons {

  }

  &__h1 {
    margin-top: 15px;
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__opener-wrap {
    display: none;
    margin-right: var(--grid-spacer);
    width: var(--sidebar-width);
    opacity: 1;
  }
}
