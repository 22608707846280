.notification-item {
  background-color: white;
  padding: 10px var(--grid-gap);
  border-radius: 16px;
  position: relative;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, .1);

  &._opened {
    .notification-item {
      &__link-icon {
        transform: rotate(-180deg);
      }
    }
  }

  &._unread {
    .notification-item {
      &__title {
        font-weight: bold;
      }
    }
  }

  &__type {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    border-radius: 16px 0 0 16px;
    &_10 {
      background-color: var(--color-40);
    }
    &_20 {
      background-color: var(--color-30);
    }
  }

  &__inner {
    display: flex;
  }

  &__link {
    display: flex;
    align-items: center;
    padding-top: 5px;
  }

  &__link-text {
    color: var(--primary-color);
    font-size: 14px;
  }

  &__link-icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    transition: transform .4s;

    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }

  &__left-wrap {
    flex: 0 0 120px;
    border-right: 1px solid var(--color-light-gray);
    margin-right: var(--grid-gap);
  }

  &__date {
    display: flex;
    flex-direction: column;
  }

  &__date-day {
    color: var(--color-black);
    font-size: 14px;
    font-weight: bold;
  }

  &__date-time {
    color: var(--color-dark-gray);
    font-size: 14px;
    font-weight: bold;
  }

  &__short {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    color: var(--color-black);
    font-size: 16px;
  }

  &__content-wrap {
    height: 0;
    overflow: hidden;
    transition: height .4s;
  }

  &__content {
    padding: 10px 0;
    color: var(--color-dark-gray);
    font-size: 16px;
  }

  &__client {
    margin-top: 10px;
    color: var(--primary-color);
    border-bottom: 1px dashed var(--primary-color-10);
    transition: border-bottom-color .4s;
    &:hover {
      border-bottom-color: var(--primary-color);
    }
  }
}
