.slot-day {
  padding: 9px 14px;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 28px;

  &__title, &__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }

  &__title {
    color: var(--color-black);
  }

  &__subtitle {
    color: var(--color-dark-gray);
  }

  &__add {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 11px;

    &:hover {
      .slot-day {
        &__icon svg path {
          fill: var(--color-dark-gray);
        }

        &__time-list {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    svg path {
      transition: fill .4s;
      fill: var(--color-light-gray);
    }
  }

  &__time-list {
    position: absolute;
    left: calc(100% - 6px);
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 500;
  }
}
