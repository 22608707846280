.salary-progress-bar {
  --plan-color: var(--color-30);

  height: 20px;
  width: 100%;
  background-color: white;
  border: 1px solid var(--primary-color);
  position: relative;

  &__plan {
    position: absolute;
    top: 0;
    height: 100%;
    transition: opacity .4s;

    &::before {
      position: absolute;
      content: '';
      left: -1px;
      top: -35px;
      width: 2px;
      bottom: -35px;
      background-color: var(--plan-color);
    }

    &:hover {
      opacity: .1;
    }

    &._executed {
      --plan-color: var(--color-green);
    }
  }

  &__plan-flag {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    background-color: var(--plan-color);
    width: 34px;
    border-radius: 3px;
    bottom: calc(100% + 35px);
    left: -17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: white;



    &::before {
      position: absolute;
      content: '';
      top: 100%;
      left: 12px;
      border: 5px solid var(--t);
      border-top-color: var(--plan-color);
    }
  }

  &__plan-amount {
    position: absolute;
    top: calc(100% + 35px);
    font-size: 10px;
    width: 100px;
    left: -50px;
    text-align: center;
    color: var(--plan-color);
    font-weight: bold;
  }

  &__current {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--primary-color);

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 2px;
      height: calc(100% + 20px);
      background-color: var(--primary-color);
    }
  }

  &__current-amount {
    position: absolute;
    top: calc(100% + 20px);
    width: 100px;
    right: -50px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-color);
  }

  &__ranges {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__range {
    position: absolute;
    top: -10px;
    height: 10px;
    border: 1px solid var(--color-light-gray);
    border-bottom: unset;
    border-top: 2px solid var(--color-light-gray);

    &._current {
      border-color: var(--color-black);
      .salary-progress-bar__range-percent {
        color: var(--color-black);
      }
    }
  }

  &__range-percent {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    font-size: 12px;
    text-align: center;
    color: var(--color-light-gray);
  }

  &__range-amount {
    position: absolute;
    top: calc(100% + 20px);
    width: 80px;
    text-align: center;
    font-size: 10px;

    &_left {
      left: -40px;
    }
    &_right {
      right: -40px;
    }
  }
}
