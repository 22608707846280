.slots-panel {
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-left: 15px;
    }
  }

  &__input {
    display: none;

    &:checked + .slots-panel__label {
      background-color: var(--primary-color);
      box-shadow: 0 6px 12px 0 rgba(63, 140, 255, 0.26);

      .slots-panel {
        &__icon svg path {
          fill: white;
        }

        &__text {
          color: white;
        }
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    padding: 4px 12px;
    box-shadow: 0 6px 58px 0 rgba(196,203,214,.1);
    cursor: pointer;
  }

  &__icon {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-right: 4px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: var(--color-dark-gray);
        transition: fill .4s;
      }
    }
  }

  &__text {
    font-size: 14px;
    color: var(--color-dark-gray);
    transition: color .4s;
  }
}
