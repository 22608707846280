.index-page {
  height: 100vh;
  width: 100vw;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    flex: 0 0 400px;
  }
}