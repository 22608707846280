.pub-item-plan-list {
  &__list {

  }

  &__item {
    display: flex;
    position: relative;
    background-color: white;
    &:nth-child(odd) {
      background-color: white;
    }
    &:nth-child(even) {
      background-color: var(--color-20-t);
    }

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }

    &._state {
      &_10 .pub-item-plan-list__status {background-color: var(--color-40)}
      &_20 .pub-item-plan-list__status {background-color: var(--color-30)}
      &_30 .pub-item-plan-list__status {background-color: var(--color-dark-gray)}
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 calc(140px + var(--grid-gap) * 2);
    width: calc(140px + var(--grid-gap) * 2);
    padding: 0 var(--grid-gap);
    border-right: 1px solid var(--border-color);
  }

  &__day {
    color: var(--color-dark-gray);
    font-size: 14px;
  }

  &__time {
    color: var(--color-black);
    font-weight: bold;
    font-size: 14px;
  }

  &__place {
    display: flex;
    align-items: center;
    flex: 1 0 calc(350px + var(--grid-gap) * 2);
    min-width: calc(350px + var(--grid-gap) * 2);
    padding: 10px var(--grid-gap);
  }

  &__category {
    color: var(--color-dark-gray);
    font-size: 14px;
    margin-right: 5px;
  }

  &__group {
    color: var(--color-black);
    font-weight: bold;
  }

  &__result {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--grid-gap);
    flex: 0 0 calc(100px + var(--grid-gap) + var(--grid-gap));
    width: calc(100px + var(--grid-gap) + var(--grid-gap));
    border-left: 1px solid var(--border-color);
  }

  &__status {
    color: white;
    background-color: var(--color-20);
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: bold;
  }

  &__message {
    margin-top: 5px;
    color: var(--color-30);
    font-size: 12px;
    display: none;
  }
}
