.any-filterable {
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 6px 58px rgba(196,203,214,.104);
  padding: 20px var(--grid-spacer) 10px;
  //display: flex;
  //flex-direction: column;

  &__head {
    //flex: 0 0 auto;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 44px;
    height: 44px;
    width: 44px;
    background-color: var(--bg-color);
    border-radius: 14px;
    transition: background-color .4s;

    &:nth-child(n + 2) {
      margin-left: 6px;
    }

    &:hover {
      background-color: var(--primary-color);
      svg path {
        fill: white;
      }
    }
  }

  &__action-icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 100%;
      height: 100%;
      width: 100%;

      path {
        fill: var(--color-black);
        transition: fill .4s;
      }
    }
  }

  &__form-wrap {
    transition: height .4s;
    height: 0;
    overflow: hidden;

    &._visible {
      overflow: visible;
    }
  }

  &__form {
  }

  &__content {
    position: relative;
    //flex: 1 1 auto;

    &._loading {
      .any-filterable__preloader-wrap {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__preloader-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity .4s;
  }

  &__list-wrap {
    height: 100%;
  }
}