.notification {
  background: #FFFFFF;
  box-shadow: 0px 12px 40px rgba(125, 131, 146, 0.21);

  animation-name: notification-in;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  width: 430px;
  position: absolute;
  left: calc(50% - 400px);
  border-radius: 20px;
  top: var(--grid-gap);

  &_out {
    animation-name: notification-out;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  &__wrapper {

  }

  &__line {
    display: flex;
    //align-items: center;
  }

  &__container {
    flex: 1 1 auto;
    margin-left: 30px;
  }

  &__close {
    flex: 0 0 50px;
    height: 40px;
    width: 50px;
    background: {
      image: url('../images/svg/notification-close.svg');
      position: 50% 50%;
      repeat: no-repeat;
    };
    position: relative;
  }

  &__content{
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
}

@keyframes notification-in {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes notification-out {
  0% {
    opacity: 1;
    pointer-events: none;
  }

  99% {
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }

  100% {
    height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }
}