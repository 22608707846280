.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }
}