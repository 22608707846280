.dashboard-page {
  padding-right: var(--grid-spacer);

  &__cats {
    grid-column: 1/6;
  }

  &__invoices, &__events {
    grid-column: 6/13;

    .any-filterable__content {
      height: 400px;
    }
  }

  &__content {
    align-items: flex-start;
  }
}