.event-item {
  &__inner {
    position: relative;
    padding: 15px 0 15px 25px;
  }


  &__top, &__middle, &__bottom {
    display: flex;
  }

  &__top, &__middle {
    justify-content: space-between;
  }

  &__middle, &__bottom {
    margin-top: 10px;
  }

  &__date-wrap {
    display: flex;
  }


  &__date {
    color: var(--color-black);
    font-size: 14px;
  }

  &__time {
    color: var(--color-dark-gray);
    font-size: 14px;
    margin-left: 6px;
  }

  &__client-wrap {
    display: flex;
  }

  &__client-name, &__organization {
    color: var(--color-black);
    font-size: 15px;
  }

  &__client-name {
  }

  &__organization {
    //margin-top: 6px;
    font-weight: bold;
    margin-left: 6px;
  }

  &__cat-wrap {
    display: flex;
  }

  &__cat {
    color: var(--color-dark-gray);
  }

  &__subcat {
    font-weight: bold;
    margin-left: 6px;
    color: var(--color-black);
  }

  &__amount-wrap {
    font-weight: bold;
    font-size: 14px;
    color: var(--color-black);
  }

  &__color-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    @for $i from 2 through 7 {
      &._color_#{$i}0 {
        background-color: var(--color-#{$i}0);
      }
    }
  }

  &__comment {
    color: var(--color-dark-gray);
    font-size: 14px;
  }
}
