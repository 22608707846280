.invoice-item {
  &__inner {
    //display: flex;
    padding: 15px 0;
    //align-items: center;
    width: 100%;
  }

  &__top, &__bottom {
    display: flex;
    width: 100%;
  }

  &__top {
    align-items: center;
  }

  &__bottom {
    justify-content: space-between;
    margin-top: 8px;
    align-items: flex-start;
  }

  &__info {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &__number {
    //flex: 0 0 75px;
    font-size: 14px;
    color: var(--color-light-gray);
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 10px;

  }

  &__part {
    flex: 0 0 200px;
    display: flex;
    align-items: center;
  }

  &__created-at{
    display: flex;
    &_status {
      margin-top: 6px;
    }
  }

  &__date {
    color: var(--color-black);
    font-size: 14px;
  }

  &__time {
    color: var(--color-dark-gray);
    font-size: 14px;
    margin-left: 6px;
  }

  &__client {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding-top: 4px;
  }

  &__name, &__organization {
    color: var(--color-black);
    font-size: 15px;
  }

  &__name {
  }

  &__organization {
    //margin-top: 6px;
    font-weight: bold;
    margin-left: 6px;
  }

  &__amount {
    color: var(--color-black);
    font-size: 18px;
    font-weight: bold;
    flex: 0 0 100px;
    display: flex;
    align-items: center;
  }

  &__status-wrap {
    flex: 1 1 auto;
    display: flex;
    //align-items: center;
    align-items: flex-end;
    //justify-content: center;
    flex-direction: column;
  }

  &__status {
    font-weight: bold;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 6px;

    &_10 {
      color: var(--primary-color);
      background-color: var(--primary-color-10);
    }
    &_20 {
      color: var(--color-50);
      background-color: var(--color-50-t);
    }
    &_30 {
      color: var(--color-40);
      background-color: var(--color-40-t);
    }
    &_40 {
      color: var(--color-30);
      background-color: var(--color-30-t);
    }
  }

  &__actions {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: var(--grid-gap);
    //padding-right: var(--grid-gap);
  }

  &__action {
    flex: 0 0 44px;
    height: 44px;
    width: 44px;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    border-radius: 14px;
    transition: background-color .4s;

    &:nth-child(n + 2) {
      margin-left: 6px;
    }

    &:hover {
      background-color: var(--primary-color);
      svg path {
        fill: white;
      }
    }

    svg {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;

      path {
        transition: fill .4s;
      }
    }
  }
}
