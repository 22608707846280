.board-cats {

  &__cats {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gap);
  }

  &__cat {
    background-color: white;
    border-radius: 24px;
    box-shadow: 0 6px 58px rgba(196,203,214,.104);
    padding: 20px var(--grid-spacer) 10px;
    transition: box-shadow .4s;

    &:hover {
      box-shadow: 0 6px 58px rgba(196,203,214,.304);

      .board-cats__arrow {
        opacity: 1;
      }
    }
  }

  &__cat-name {
    color: var(--primary-color);
  }

  &__cat-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__arrow {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity .4s;
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0;
    &:nth-child(n + 2) {
      border-top: 1px dashed var(--border-color);
    }
  }

  &__info-name {
    font-size: 15px;
    color: var(--color-dark-gray);
  }

  &__info-value {
    font-size: 15px;
    color: var(--primary-color);

    &._loading {
      animation: rotator infinite 1s linear;
      font-weight: bold;
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}