.login-form {
  background-color: white;
  border-radius: 24px;
  padding: var(--grid-spacer);
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);

  &__title {}
  &__form {}

  &__subtitle{
    margin-top: 10px;
  }
  &__fields {
    margin: var(--grid-spacer) 0;
  }

  &__button-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__recovery-link{
    color: var(--primary-color);
    border-bottom: 1px dotted transparent;
    transition: border .5s;
    &:hover{
      border-bottom: 1px dotted var(--primary-color);
    }
  }
}