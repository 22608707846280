.invoice-list {
  overflow: auto;
  height: 100%;

  &__list {
  }

  &__item-wrap {
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__item {

  }

  &__empty {

  }
}
