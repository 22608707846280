.group-matcher {
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 6px 58px rgba(196, 203, 214, .104);
  padding: 20px var(--grid-spacer) 10px;

  &__title {
    margin-bottom: var(--grid-gap);
  }

  &__block-name {
    font-weight: bold;
  }
  &__block {
    padding-top: 10px;
  }

  &__block-content {
    display: flex;
    width: 100%;
  }

  &__half {
    flex: 0 0 50%;
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    display: flex;
    width: 100%;
  }
}