.client-info {
  background-color: white;
  box-shadow: 0 6px 58px rgba(196,203,214,.104);
  padding: 20px var(--grid-spacer);
  border-radius: 24px;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--grid-gap);
  }

  &__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 44px;
    width: 44px;
    height: 44px;
    background-color: var(--bg-color);
    border-radius: 14px;
    transition: background-color .4s;
    padding-left: 1px;

    svg {
      flex: 0 0 24px;
      height: 24px;
      width: 24px;
      path {
        transition: fill .4s;
      }
    }

    &:hover {
      background-color: var(--primary-color);
      svg path {
        fill: white;
      }
    }
  }

  &__info {

  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
    }
  }

  &__name {
    color: var(--color-dark-gray);
    font-size: 14px;
  }

  &__value {
    color: var(--color-black);
    font-size: 15px;
    cursor: copy;
    width: fit-content;
    border: 1px solid transparent;
    transition: border .2s;
    padding: 2px;
    margin-left: -2px;
    &._copied{
      border: 1px solid green;
    }
  }
}