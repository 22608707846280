.publication-form {
  min-width: 650px;

  &__fields-wrap {
    display: flex;
    border-bottom: 1px solid var(--border-color);
  }

  &__fields {
    padding-bottom: var(--grid-gap);
    &_right {
      padding-left: var(--grid-gap);
      margin-left: var(--grid-gap);
      border-left: 1px solid var(--border-color);
    }
  }

  &__fields-group {
    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
      padding-top: var(--grid-gap);
      border-top: 1px solid var(--border-color);
    }
  }

  &__form-wrap {
    position: relative;
    padding-bottom: calc(48px + var(--grid-gap));
  }

  &__after-form {
    padding: var(--grid-gap) 0;
  }


  &__field {
    position: relative;

    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
    }
    textarea {
      height: 200px;
      resize: none;
    }
  }

  &__button-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
  }

  &__form {

  }

  &__publication-info {
    margin-left: var(--grid-gap);
    border-radius: 6px;
    border: 1px solid var(--border-color);
    max-width: 350px;
  }

  &__emoji {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 500;
  }
}
