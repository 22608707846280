.multi-additional-form {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--primary-color);
  border-radius: 14px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 250px;
    justify-content: center;
    &._opened{
      .multi-additional-form{
        &__block-wrap{
          overflow: unset;
          z-index: 100;
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__link {
    color: var(--primary-color);
    border-bottom: 1px dashed var(--primary-color);
  }

  &__block-wrap {
    position: absolute;
    bottom: 50px;
    //left: 0;
    width: auto;
    opacity: 0;
    right: 0;
    height: auto;
    pointer-events: none;
    overflow: hidden;
    transition: height .4s;
    background-color: white;
    border-radius: 14px;
    box-shadow: 0 6px 58px rgba(196, 203, 214, .104);
  }

  &__block {
    padding: 10px;
    min-height: 400px;
    min-width: 653px;
    border: 1px solid var(--border-color);
    border-radius: 14px;
  }

  &__fields {
    display: flex;
    align-items: flex-start;
  }

  &__field {
    flex: 0 0 auto;
    margin-right: 10px;

  }

  &__category-fields {
    flex: 0 0 auto;
  }
}