.clients-filter{
  padding-bottom: 30px;
  max-width: 80%;
  &__fields{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__field{
    &:first-child{
      grid-column: 1 / 4;
    }
  }
}