.info-group-items {
  &__data-list {
    width: 100%;
  }

  &__data-item {
    width: 100%;

    margin-top: var(--grid-gap);

    &._error, &._warning, &._skip {
      .info-group-items__title {
        color: var(--color-red);
      }
    }

    &._success {
      .info-group-items__title {
        color: var(--color-green);
      }
    }

    &._await {
      .info-group-items__title {
        color: var(--primary-color);
      }
    }
  }

  &__title {
    font-weight: bold;
  }

  &__items {
    width: 100%;
  }

  &__item {
    width: 100%;

    &:nth-child(n+2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__item-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: background-color .4s;
    padding: 10px 5px;

    &:hover {
      background-color: var(--color-20-t);
    }
  }

  &__date {
    display: flex;
    margin-bottom: 3px;
  }

  &__date-day {
    color: var(--color-dark-gray);
    font-size: 12px;
  }

  &__date-time {
    color: var(--color-black);
    font-weight: bold;
    font-size: 12px;
    margin-left: 3px;
  }

  &__name {
    display: inline-flex;
  }

  &__name-group {
    color: var(--color-black);
    font-size: 14px;
    margin-left: 3px;
    font-weight: bold;
  }

  &__name-category {
    font-size: 14px;
    color: var(--color-dark-gray);
  }

  &__message {
    font-size: 12px;
    color: var(--color-red);
    margin-top: 5px;
  }
}
