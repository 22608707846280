.create-link-part{
  &__subtitle{
    margin-bottom: 10px;
  }

  &__error{
    margin-top: 10px;
    color: var(--color-red);
  }

  &__wrap{
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__copy-result{
    opacity: 0;
    pointer-events: none;
    margin-top: 10px;
    &._copy{
      opacity: 1;
      pointer-events: all;
    }
  }
}