.stats{
  background: #fff;
  padding: var(--grid-gap);
  border-radius: 10px;

  &__category-name{
    @extend h2;
    padding-bottom: 15px;
    padding-top: 25px;
  }

  &__group-name{
    background: var(--primary-color);
    color: #FFFFFF;
    padding: 10px 20px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    width: 140px;
  }

  &__row{
    display: flex;
  }

  &__column{
    &._total{
      border-left: 1px solid var(--primary-color);
      border-right: 1px solid var(--primary-color);
    }
    &:first-child{
      .stats__group-name{
        border-radius: 10px 0 0 0;
        width: 205px;
      }
      .stats__cell{
        width: 205px;
      }
    }

    &:last-child{
      .stats__group-name{
        border-radius: 0 10px 0 0;
      }
    }
  }

  &__cell{
    width: 140px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:nth-child(even) {
      background: var(--color-20-tt);
    }
    &:nth-child(odd) {
      background: var(--color-20-t);
    }

    &_width{
      width: 335px !important;
    }
  }
}