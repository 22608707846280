:root {
  --default-input-height: 49px;
  --errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 171%;
    color: var(--color-dark-gray);
    margin-bottom: 6px;

    &.invalid {
      color: var(--alert-color);
    }
  }

  ul.errors {
    position: relative;
    list-style: none;


    li {
      position: absolute;
      left: 0;
      font-size: 13px;
      color: var(--errors-color);
      background-color: white;
      border-radius: 6px;
      padding: 1px 8px;
      margin-bottom: 0;
      z-index: 50;
      white-space: nowrap;
      box-shadow: 0 0 7px white;
      border: 1px solid var(--alert-color);
      min-width: 100%;
      text-align: center;

      &:nth-child(1) {top: 2px}
      &:nth-child(2) {top: 24px}
      &:nth-child(3) {top: 46px}
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 1px solid var(--border-color);
    border-radius: 14px;
    margin: 0;
    padding: 12px 18px;
    font-size: 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.22);
    background: white;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 171%;
      color: var(--plaeholder-color);
    }

    &.invalid {
      border: 1px solid var(--alert-color);
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }
}