form {
  input[type="checkbox"] + label {
    &::before {
      border-radius: 4px;
    }

    &::after {
      height: 20px;
      width: 20px;
      left: 0;
      top: 0;
      object-fit: contain;
      background: {
        position: center;
        size: 24px 24px;
        repeat: no-repeat;
        image: url('../images/svg/checkbox.svg');
      }
    }
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;

    + label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 5px 0;
      padding-left: 30px;
      line-height: 128%;
      font-weight: 400;
      font-size: 16px;
      color: var(--color-dark-gray);

      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        left: 0;
        top: 0;
        border: 2px solid var(--color-black);
      }

      &::after {
        content: '';
        position: absolute;
        opacity: 0;
        transition: {
          property: opacity;
          duration: .3s;
        };
      }
    }

    &:checked {
      + label {
        &::after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      + label {
        opacity: .7;
        &::before {
          background-color: var(--color-light-gray);
          border-color: var(--color-dark-gray);
        }
        &::after {
          opacity: .7;
        }
      }
    }
  }


  input[type="radio"] + label {
    &::before {
      border-radius: 100px;
    }

    &::after {
      background-color: var(--primary-color);
      border-radius: 100px;
      height: 10px;
      left: 5px;
      top: 5px;
      width: 10px;
    }
  }
}
