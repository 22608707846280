.notifications {
  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  z-index: 10000;

  &__list{
    position: relative;
  }
}