button, .button, .button-icon {
  margin: 0;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border-radius: 14px;
  padding: 13px 20px;

  &:not(.button-icon), .button-icon__text  {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transition: color .4s;
  }

  &:disabled, &._disabled {pointer-events: none;}

  &_primary {
    background-color: var(--primary-color);
    box-shadow: 0 6px 12px 0 rgba(63, 140, 255, 0.26);
    border: none;
    transition: background-color .4s;

    &:not(.button-icon), .button-icon__text {
      color: white;
    }

    .button-icon__icon svg path {
      &[stroke] {stroke: white}
      &[fill] {fill: white}
    }

    &:hover {background-color: var(--primary-hover)}
    &:active {background-color: var(--primary-active)}
    &:disabled, &._disabled {
      background-color: var(--disable-color);
      box-shadow: none;
    }
  }
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__text, &__icon {
    &:first-child {
      &:not(:last-child) {
        margin-right: 11px;
      }
    }
  }

  &__text {}

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    svg path {
      transition: {
        property: stroke, fill;
        duration: .4s;
      };
    }
  }
}