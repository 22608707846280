.slots-page {
  &__h1-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 50px;
  }

  &__head {

  }

  &__content {
    padding: var(--grid-spacer);
  }

  &__data {

  }
}
