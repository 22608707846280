.range-input {
  &__values {
    display: flex;
    position: relative;
    background-color: white;

    &_double {
    }

    &_single {
    }
  }

  &__value {
    position: relative;

    .range-input__input {
      padding-left: 54px;
    }

    &_from, &_to {
      flex: 0 0 50%;
    }

    &_single {
      flex: 0 0 100%;
    }
  }

  &__show {
  }

  &__prefix {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 0;
    height: var(--default-input-height);
    align-items: center;
  }

  .range-input__line{
    height: 19px;
    padding: 8px 0;
    border: none;
    box-shadow: none;

    .noUi-connects {
      border-radius: 0 0 100px 100px;
      background-color: white;
    }
    .noUi-connect{
      background-color: var(--primary-color);
      cursor: pointer;
    }

    .noUi-handle{
      position: absolute;
      height: 13px;
      width: 13px;
      top: -5px;
      right: -6px;
      border: 2px solid black;
      border-radius: 100px;
      background: white;
      cursor: pointer;
      box-shadow: none;

      &::after, &::before{
        display: none;
      }
    }
  }
}