h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-weight: 700;
  color: var(--color-black);
}

h1, .h1 {
  --h-size: 36px;
}

h2, .h2 {
  --h-size: 22px;
}

h3, .h3 {
  --h-size: 18px;
}

h4, .h4 {
  --h-size: 16px;
}

h5, .h5 {
  --h-size: 16px;
}

h6, .h6 {
  --h-size: 16px;
}