.slots-filter-form {
  &__form {

  }

  &__fields {
    display: flex;
  }

  &__field-wrap{
    display: flex;
    align-items: center;
  }

  &__field {
    &:nth-child(n + 2) {
      margin-left: 25px;
    }
  }

  &__button-wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 16px 10px 0;
  }

  &__button{
    border-radius: 6px;
    background: var(--primary-color);
    color: #FFFFFF;
    padding: 1px 3px;
    font-size: 12px;
    width: 55px;
    text-align: center;
    margin: 2px 0;
  }
}
