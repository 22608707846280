.notification-page {
  padding-right: var(--grid-spacer);
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: repeat(12, 1fr);

  &__list {
    grid-column: 1/10;
  }
}
