.images-field {
  border-radius: 10px;

  &__input-wrap {
    width: 100%;
    position: relative;
    border: 5px dashed var(--color-light-gray);
    border-radius: 10px;
    transition: border-color .4s;

    &:hover {
      border-color: var(--color-dark-gray);

      .images-field {
        &__text {
          color: var(--color-black);
        }
        &__icon {
          svg path {
            fill: var(--color-dark-gray);
          }
        }
      }
    }
  }

  &__info {
    position: relative;
    padding: var(--grid-gap);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__icon {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-bottom: var(--grid-gap);

    svg path{
      fill: var(--color-light-gray);
      transition: fill .4s;
    }
  }

  &__text {
    font-weight: bold;
    color: var(--color-dark-gray);
    transition: color .4s;
    text-align: center;
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 300;
  }

  &__list-wrap {
    padding-top: var(--grid-gap);
  }

  &__list {
    display: flex;
    margin: 0 -6px;
  }

  &__item {
    padding: 0 6px;
    flex: 0 0 120px;
    width: 120px;
    position: relative;
  }

  &__link {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    border: 1px solid var(--color-dark-gray);
    transition: border-color .4s;
    &:hover {
      border-color: var(--primary-color);
    }
  }


  &__closer {
    position: absolute;
    border-radius: 20px;
    background-color: white;
    border: 1px solid var(--primary-color);
    height: 16px;
    right: 4px;
    top: -6px;
    width: 16px;
    transition: {
      property: background-color, border-color;
      duration: .3s;
    };

    &:hover {
      background-color: var(--primary-color);

      &::before, &:after {
        background-color: white;
      }
    }

    &::before, &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 1px;
      transform-origin: center;
      background-color: var(--primary-color);
      top: 7px;
      left: 3px;
      transition: {
        property: background-color;
        duration: .3s;
      };
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__errors {

  }
}
