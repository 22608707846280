.emoji-block {

  &__wrap {
    position: relative;
    &:hover {
      .emoji-block {
        &__emoji {
          opacity: 1;
          pointer-events: all;
        }
        &__icon {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__icon {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 30px;
      height: 30px;
      opacity: .3;
      transition: opacity .4s;

      circle, path, ellipse {
        &[fill] {fill: var(--color-dark-gray)}
        &[stroke] {stroke: var(--color-dark-gray)}
      }
    }
  }

  &__emoji {
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
}