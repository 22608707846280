.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("small") {
  .breadcrumbs {
    margin-top: 50px;

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;

      &:hover {
      }
    }
  }
}
