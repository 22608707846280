.slot-save-form {
  min-width: 650px;
  position: relative;
  padding-bottom: 80px;

  &__heading {
    margin-bottom: 25px;
  }

  &__h2 {
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-gap);
  }

  &__target {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color);
    border-radius: 24px;
    padding: 17px 28px;
  }

  &__target-named {
  }

  &__target-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-black);
  }

  &__target-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-dark-gray);
    margin-top: 5px;
  }

  &__field {
    &_wide {
      grid-column: 1/3;
    }

    textarea {
      height: 120px;
      resize: none;
    }
  }

  &__time-field {
    width: 112px;
    flex: 0 0 112px;
  }

  &__button-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--grid-gap);
  }

  &__add-link {
    display: flex;
    align-items: center;
  }

  &__add-link-name {
    color: var(--primary-color);
  }

  &__add-link-icon {
    display: flex;
    flex: 0 0 24px;
    height: 24px;
    width: 24px;

    svg path {
      fill: var(--primary-color);
    }
  }

  &__additional {
    margin-top: var(--grid-gap);
  }

  &__additional-label {
    color: var(--color-dark-gray);
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 171%;
    margin-bottom: 6px;
  }
}