.slot-additional-create {


  &:nth-child(n + 2) {
    margin-top: calc(var(--grid-gap) / 2);
    padding-top: calc(var(--grid-gap) / 2);
    border-top: 1px solid var(--border-color);

    .slot-additional-create__field > label {
      display: none;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__errors {
    li {
      font-size: 13px;
      color: var(--alert-color);
      background-color: white;
      border-radius: 6px;
      padding: 1px 8px;
      z-index: 50;
      white-space: nowrap;
      box-shadow: 0 0 7px white;
      border: 1px solid var(--alert-color);
      min-width: 100%;
      text-align: center;
      margin-top: 3px;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
  }

  &__field {
    &:nth-child(n + 2) {
      margin-left: calc(var(--grid-gap) / 2);
    }

    .custom-select__choice-name {
      white-space: nowrap;
    }

    &_group {
      flex: 0 0 220px;
      width: 220px;
    }

    &_amount {
      flex: 0 0 70px;
      width: 70px;
    }

    &_date {
      .data-input input {
        width: 126px;
      }
    }

    &_time {
      .custom-select__field {
        width: 100px;
      }
    }
  }

  &__actions {
    margin-left: var(--grid-gap);
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
