.slots-head {
  padding-right: var(--grid-spacer);

  &__content {
    position: relative;
  }

  &__accordion-element {
    &._opened {
      .slots-head__accordion-block {
        height: unset;
      }
    }
  }

  &__opener {
    display: none;
  }

  &__accordion-block {
    height: 0;
    overflow: hidden;
    transition: height .4s;

    &._visible {
      overflow: visible;
    }
  }

  &__block-inner {
    padding-bottom: 24px;
  }

  &__filters {
    //height: 0;
    //overflow: hidden;
    //transition: height .4s;
  }

  &__months{
    margin-top: 20px;
  }

  &__month-button{
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px !important;
    border-radius: 8px;
    background: var(--color-bg-element);
    transition: background .3s, color .3s;
    &._active{
      background: var(--primary-color);
      color: #FFFFFF;
      font-weight: bold;
    }
  }

  &__category {}
  &__form {}

}
