/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  large: (
    from: 768,
  ),
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Nunito Sans', "Arial", sans-serif;
  --h-font: 'Nunito Sans', "Arial", sans-serif;

  --t: transparent;

  --primary-color: #3F8CFF;
  --primary-hover: #3A81EB;
  --primary-active: #1F6DE0;
  --primary-color-10: rgba(63, 140, 255, 0.1);
  --alert-color: #F65160;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --bg-color: #F4F9FD;
  --disable-color: #CED5E0;

  --color-dark-gray: #7D8592;
  --color-light-gray: #C9CCD1;
  --color-bg-element: #E6EDF5;
  --color-black: #0a1629;
  --border-color: #d8e0f0;
  --plaeholder-color: #b2b8c2;

  --table-dark-color: #C3D0E6;
  --table-light-color: #E6EBF5;

  --color-green: #00D097;
  --color-red: #F65160;
  --color-green-10: rgba(0, 208, 151, 0.1);

  --color-10: transparent;
  --color-20: #3F8CFF;
  --color-30: #F65160;
  --color-40: #00D097;
  --color-50: #6D5DD3;
  --color-60: #C418E6;
  --color-70: #7D8592;
  --color-80: #FFFF00;
  --color-90: #D40098;
  --color-100: rgba(230, 230, 227, 1);

  --color-10-t: transparent;
  --color-20-t: rgba(63, 140, 255, 0.1);
  --color-20-tt: rgba(63, 140, 255, 0.2);
  --color-30-t: rgba(246, 81, 96, 0.1);
  --color-30-tt: rgba(246, 81, 96, 0.4);
  --color-40-t: rgba(0, 208, 151, 0.1);
  --color-50-t: rgba(109, 93, 211, 0.1);
  --color-60-t: rgba(196, 24, 230, 0.1);
  --color-70-t: rgba(125, 133, 146, 0.1);
  --color-80-t: rgba(255, 255, 0, 0.3);
  --color-90-t: rgba(212, 0, 152, 0.3);
  --color-100-t: rgba(230, 230, 227, 0.3);
}
