.publication-info {
  &__inner {
    padding: var(--grid-gap);
  }
  &__title {

  }

  &__data-title {
    font-weight: bold;
  }

  &__status-message {
    margin-top: var(--grid-gap);
    color: var(--color-red);
  }

  &__client-errors {
    margin-top: var(--grid-gap);
  }

  &__client-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color .4s;
    padding: 10px 5px;
    &:hover {
      background-color: var(--color-20-t);
    }
  }

  &__error {
    font-size: 12px;
    color: var(--color-red);
    margin-top: 5px;
  }
}