.sidebared-layout {
  --sidebar-width: 200px;
  --sidebar-with-spacer: calc(var(--sidebar-width) + var(--grid-spacer));
  --avail-content-width: calc(100vw - var(--sidebar-with-spacer));

  display: flex;
  background-color: var(--bg-color);

  &__sidebar-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: var(--sidebar-with-spacer);
    background-color: var(--bg-color);
    //padding: var(--grid-spacer) 0 var(--grid-spacer) var(--grid-spacer);
    z-index: 100;
    overflow: hidden;
    transition: width .4s;
  }

  &__sidebar-space {
    position: relative;
    width: var(--sidebar-width);
    margin: var(--grid-spacer) 0 var(--grid-spacer) var(--grid-spacer);
    height: calc(100vh - var(--grid-spacer) * 2);
  }

  &__sidebar {
    --sidebar-shift: calc(var(--sidebar-with-spacer) - var(--sidebar-width) - var(--grid-spacer));
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translate3d(var(--sidebar-shift), 0, 0);
    transition: transform .4s;
  }

  &__main {
    z-index: 90;
  }

  &__content {
    padding-left: 20px;
    width: var(--avail-content-width);
    transition: width .4s;
    &._width{
      width: unset;
    }
  }

  // Спрятанный сайдбар
  &._hide-menu {
    --sidebar-with-spacer: 0px;
    --avail-content-width: 100vw;

    //.sidebared-layout__sidebar-wrap {display: none}
    .layout-heading__opener-wrap {display: flex}
  }

  // Обычный лайаут
  &._relative {
    padding-left: var(--sidebar-with-spacer);
    transition: padding-left .4s;

    .sidebared-layout {
      &__main {
        position: relative;
        flex: 1 1 auto;
        min-height: 100vh;
      }
    }
  }

  // Лайаут для скрол талицы
  &._fixed {
    // Верхний отступ
    --heading-heigth: 130px;
    --categories-heigth: 0px;
    --filters-heigth: 0px;
    &._opened-categories {--categories-heigth: 118px}
    &._opened-filters {--filters-heigth: 113px}
    --top-padding: calc(var(--heading-heigth) + var(--categories-heigth) + var(--filters-heigth));

    .sidebared-layout {
      &__main {
        position: fixed;
        left: var(--sidebar-with-spacer);
        right: 0;
        background-color: var(--bg-color);
        transition: left .4s;
      }

      &__main-scrollable {
        transform: translate3d(var(--sidebar-with-spacer), var(--top-padding), 0);
        transition: transform .4s;
      }
    }
  }
}
