.sidebar {
  --sidebar-padding: 16px;
  padding: 0 var(--sidebar-padding) 30px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;

  box-shadow: 0 6px 58px rgba(196, 203, 214, 0.103611);

  &__closer-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    flex: 0 0 auto;
  }

  &__menu {
    flex: 1 1 auto;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 8px 0;

    &._closer {
      .sidebar {
        &__icon  {
          margin-right: 5px;

          svg path {
            fill: var(--color-light-gray);
          }
        }

        &__text {
          color: var(--color-light-gray);
        }
      }

      &:hover {
        .sidebar {
          &__icon svg path {
            fill: var(--color-dark-gray);
          }

          &__text {
            color: var(--color-dark-gray);
          }
        }
      }
    }

    &._logout {
      .sidebar {
        &__icon  {
          margin-right: 10px;

          svg path {
            fill: var(--color-dark-gray);
          }
        }

        &__text {
          color: var(--color-dark-gray);
        }
      }

      &:hover {
        .sidebar {
          &__icon svg path {
            fill: var(--primary-color);
          }

          &__text {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    svg path {
      transition: fill .4s;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    transition: color .4s;
  }

  &__logout-wrap {
  }
}
