.slots-cell {
  width: 100%;
  height: 100%;

  &__content {
    height: 100%;
    width: 100%;
    position: relative;

    &:hover {
      .slots-cell {
        &__info-wrap {
          opacity: 1;
          pointer-events: all;
          z-index: 500;
        }

        &__name {color: white;}

        &__link {
          background-color: var(--color-black);

          @for $i from 2 through 10 {
            &._color_#{$i}0 {
              background-color: var(--color-#{$i}0);
            }
            .slots-cell__name {color: white}
          }

          &._color_80{
            background-color: var(--color-80);
            .slots-cell__name {
              color: var(--color-black);
            }
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    @for $i from 2 through 10 {
      &._color_#{$i}0 {
        background-color: var(--color-#{$i}0-t);
        .slots-cell__name {
          color: var(--color-black);
        }
      }
    }
  }

  &__name {
    white-space: nowrap;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-black);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__info-wrap {
    position: absolute;
    top: 100%;
    left: -20px;
    width: 100%;
    background-color: white;
    opacity: 0;
    pointer-events: none;

    box-shadow: 0 0 15.9px rgba(196, 203, 214, 0.5);
    border-radius: 8px 0 8px 8px;

    &::before {
      content: '';
      position: absolute;
      top: -14px;
      right: 0;
      border: 7px solid var(--t);
      border-right: 12px solid var(--t);
      border-left: 12px solid var(--t);
      border-bottom-color: white;
    }
  }

  &__info-list {
    padding: 0 8px;
  }

  &__info-item {
    padding: 10px 0;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__info-name {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: var(--color-dark-gray);
    text-align: left;
  }

  &__info-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-black);
    text-align: left;
  }


  &__client-link {
    color: var(--primary-color);
    border-bottom: 1px dotted transparent;
    transition: border .3s;

    &:hover {
      border-bottom: 1px dotted var(--primary-color);
    }
  }

  &__info-link {
    width: 100%;
    display: block;
    font-size: 12px;
    background-color: var(--primary-color-10);
    color: var(--primary-color);
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: bold;
    transition: {
      property: background-color, color;
      duration: .4s;
    };

    &:hover {
      background-color: var(--primary-color);
      color: white;
    }
  }
}