.main-menu-opener {
  &__link {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 24px;
    padding: 8px 16px;

    &:hover {
      .main-menu-opener {
        &__icon svg path {
          fill: var(--primary-color);
        }

        &__text {
          color: var(--primary-color);
        }
      }
    }
  }

  &__icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    box-shadow: 0 6px 58px rgba(196, 203, 214, 0.103611);

    svg {
      transform: rotate(180deg);

      path {
        transition: fill .4s;
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-dark-gray);
    white-space: nowrap;
    transition: color .4s;
  }
}
