.heading-buttons {
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
    box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);
    border-radius: 14px;
    background-color: white;
    position: relative;

    &:nth-child(n + 2) {
      margin-left: var(--grid-spacer);
    }

    &._alarm {
      padding: 8px;
    }

    &._profile {
      padding: 8px 12px;
    }

    &:hover {
      .heading-buttons {
        &__icon svg path {
          fill: var(--primary-color);
        }

        &__text {
          color: var(--primary-color);
        }
      }
    }
  }

  &__icon {
    flex: 0 0 24;
    width: 24px;
    height: 24px;

    svg path {
      fill: var(--color-black);
      transition: fill .4s;
    }

    &._alarm {
    }

    &._profile {
    }
  }

  &__text {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    transition: color .4s;
  }

  &__count {
    position: absolute;
    font-size: 12px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 20px;
    right: -6px;
    top: -6px;
    width: 17px;
    height: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
