.preloader {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 15px);
  grid-template-rows: repeat(3, 15px);
  overflow: hidden;
  border-radius: 6px;

  &__square {
    background-color: var(--primary-color);
    border-radius: 2px;
    animation: preloader-square 1s infinite;

    //1 2 3
    //4 5 6
    //7 8 9
    &:nth-child(1) {animation-delay: .0s}
    &:nth-child(2) {animation-delay: .1s}
    &:nth-child(3) {animation-delay: .2s}

    &:nth-child(4) {animation-delay: .3s}
    &:nth-child(5) {animation-delay: .4s}
    &:nth-child(6) {animation-delay: .5s}

    &:nth-child(7) {animation-delay: .6s}
    &:nth-child(8) {animation-delay: .7s}
    &:nth-child(9) {animation-delay: .8s}
  }


}

@keyframes preloader-square {
  0% {opacity: 1}
  50% {opacity: .1}
}