.publications-page {
  padding-right: var(--grid-gap);

  &__content {

  }

  &__filterable {

  }

  &__list {
    width: 100%;
    overflow: scroll;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__action {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  &__action-icon {
    margin-right: 5px;
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: white;
      }
    }
  }

  &__action-text {
    pointer-events: none;
  }
}
