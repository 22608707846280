.publication-item {
  background-color: white;

  &__inner {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(350px + var(--grid-gap) * 2);
    width: calc(350px + var(--grid-gap) * 2);
    position: relative;
  }

  &__content, &__info {
    padding: var(--grid-gap);
  }

  &__info, &__plan {
    border-left: 1px solid var(--border-color);
  }

  &__images {
    margin-top: var(--grid-gap);
  }

  &__info {
    flex: 0 0 calc(350px + var(--grid-gap) * 2);
    width: calc(350px + var(--grid-gap) * 2);
  }

  &__plan {
    flex: 1 1 auto;
  }

  &__text {
    font-size: 14px;
  }

  &__actions {
    margin-top: var(--grid-gap);
    flex: 1 1 auto;
    display: flex;
  }

  &__action {
    display: flex;
    align-items: flex-start;

    &._edit {
      .publication-item__action-icon, .publication-item__action-text {
        pointer-events: none;
      }
    }
  }

  &__action-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
      path {
        &[fill] {
          fill: var(--primary-color);
        }
        &[stroke] {
          stroke: var(--primary-color);
        }
      }
    }
  }

  &__action-text {
    font-size: 14px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
  }
}
