.password-input {
  &__input {
    position: relative;

    input[type='password'] {
      padding-right: 41px;
    }
  }

  &__icon {
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    &_eye {
      opacity: 1;
    }

    &_eye-off {
      opacity: 0;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
  }

  .errors {
    position: absolute;
  }

  &._visible {
    .password-input__icon {
      &_eye {
        opacity: 0;
      }

      &_eye-off {
        opacity: 1;
      }
    }
  }

  &._filled {
    .password-input__link {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
